<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-input
                v-model="formData.goodsName"
                clearable
                placeholder="商品名"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formData.goodsId"
                clearable
                placeholder="商品ID"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formData.thirdGoodsId"
                clearable
                placeholder="第三方商品ID"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formData.packageName"
                clearable
                placeholder="包名"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-select
                v-model="formData.channel"
                placeholder="渠道"
                clearable
              >
                <el-option
                  v-for="(item, index) in channelList"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.timeType"
                placeholder="时间类型"
                clearable
              >
                <el-option
                  v-for="(item, index) in timeTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              prop="timeTimes"
            >
              <el-input
                v-model="formData.timeTimes"
                placeholder="时间数量"
                clearable
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="handleSearch"
              >
                搜索
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template v-slot:right>
        <div style="width: 240px;">
          <el-form>
            <el-form-item>
              <el-button
                :loading="loading"
                type="primary"
                @click="handleSyncData"
              >
                一键同步
              </el-button>

              <el-button
                :loading="loading"
                plain
                @click="handleCreateNewGoodsItem"
              >
                新建商品
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </BHeader>
    <el-table
      v-loading="priceListInfo.loading"
      :data="priceListInfo.list"
      stripe
    >
      <el-table-column
        prop="goodsId"
        label="商品ID"
        min-width="100px"
        sortable
      />
      <el-table-column
        prop="pApplicationId"
        label="包名"
        min-width="100px"
      />
      <el-table-column
        prop="appId"
        label="appId"
        min-width="100px"
        sortable
      />
      <el-table-column label="时间">
        <el-table-column
          prop="timeType"
          label="时间类型"
          min-width="110px"
          sortable
        >
          <template v-slot="{row}">
            {{ row.timeType | timeType }}
          </template>
        </el-table-column>
        <el-table-column
          prop="timeNumber"
          label="时间数量"
          min-width="110px"
          sortable
        >
          <template v-slot="{row}">
            {{ row.timeNumber }} {{ row.timeType | timeType }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="name"
        label="商品名称"
        min-width="110px"
        fixed
        sortable
      />
      <el-table-column
        prop="description"
        label="简介"
        min-width="100px"
        sortable
      />
      <el-table-column
        prop="content"
        label="内容"
        min-width="100px"
        sortable
      />
      <el-table-column
        prop="channel"
        label="渠道"
        min-width="100px"
        sortable
      >
        <template v-slot="{row}">
          {{ row.channel | channelFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="Type"
        min-width="100px"
        sortable
      >
        <template v-slot="{row}">
          {{ row.type | typeFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="thirdGoodsId"
        label="第三方平台商品ID"
        min-width="170px"
        sortable
      />
      <el-table-column
        prop="topUpCardTime"
        label="时长卡时间"
        min-width="120px"
        sortable
      />
      <el-table-column
        prop="topUpCardTime"
        label="时长卡时间"
        min-width="130px"
        sortable
      />
      <el-table-column
        prop="price"
        label="商品价格"
        min-width="110px"
        sortable
      />
      <el-table-column
        prop="currency"
        label="币种"
        min-width="100px"
        sortable
      />
      <el-table-column
        prop="subscriptionTime"
        label="订阅商品的总时间"
        min-width="100px"
        sortable
      />
      <el-table-column
        prop="experimentalGroup"
        label="实验组"
        min-width="100px"
        sortable
      >
        <template v-slot="{row}">
          {{ row.experimentalGroup| experimentalGroupFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="isDel"
        label="是否删除"
        min-width="110px"
        sortable
      >
        <template v-slot="{row}">
          {{ row.isDel | isDelFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="discount"
        label="折扣"
        min-width="100px"
        sortable
      />
      <el-table-column
        prop="status"
        label="Status"
        min-width="100px"
        sortable
      >
        <template v-slot="{row}">
          {{ row.status | statusFilter }}
        </template>
      </el-table-column>
      <el-table-column
        label="Limit"
        align="center"
      >
        <el-table-column
          prop="isLimit"
          label="订阅商品是否限制商品"
          min-width="100px"
          sortable
        >
          <template v-slot="{row}">
            {{ row.isLimit | isLimitFilter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="limitTimeType"
          label="限制的时间类型"
          min-width="100px"
          sortable
        >
          <template v-slot="{row}">
            {{ row.limitTimeType | limitTimeTypeFilter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="limitTimeNumber"
          label="限制的时间数量"
          min-width="100px"
          sortable
        >
          <template v-slot="{row}">
            {{ row.limitTimeNumber }} {{ row.limitTimeType | limitTimeTypeFilter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="limitTotalAmount"
          label="限制的时间总数量(秒)"
          min-width="100px"
          sortable
        />
        <el-table-column
          prop="limitPerAmount"
          label="限制的单位时间数量(秒)"
          min-width="100px"
          sortable
        />
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        min-width="160px"
        sortable
      />
      <el-table-column
        prop="updateTime"
        label="修改时间"
        min-width="160px"
        sortable
      />
      <el-table-column
        prop="image"
        label="图片地址"
        min-width="200px"
        sortable
      >
        <template v-slot="{row}">
          <template v-if="row.image && row.image.length">
            <div
              v-for="(imageUrl, index) in row.image"
              :key="index"
            >
              {{ imageUrl }}
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        width="150px"
      >
        <template #default="{row}">
          <el-button
            :loading="row.loading"
            style="width: 100px"
            :type="row.status === 1 ? 'danger' : 'primary' "
            @click="handleUpdateStatus(row)"
          >
            {{ row.status === 1 ? '下架' : '上架' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="priceListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryPriceList(formData)"
    />
  </Layout>
</template>

<script>
import { updatePriceInfo, updateStatus } from '@/api/RechargeSystem'
import {
  mapActions,
  mapState
} from 'vuex'

const fieldMap = {
  goodsId: '商品ID',
  appId: 'appId',
  timeType: '时间类型', // (1.day 2.month 3.year)
  timeNumber: '时间数量', // (多少天|月|年)
  name: '商品名称',
  description: '简介',
  content: '内容',
  channel: '渠道', // (1.谷歌商城 2.IOS商城)
  type: 'Type', // 1.时长卡商品 2.会员商品
  thirdGoodsId: '第三方平台商品ID',
  topUpCardTime: '时长卡时间',
  price: '商品价格',
  currency: '币种',
  subscriptionTime: '订阅商品的总时间',
  experimentalGroup: '实验组', // (0:基准组 1:V1体验组 2:V2体验组)
  isDel: 'isDel', // 是否删除(1.是 2.不是)
  discount: '折扣', // 折扣(打几折)
  status: 'Status', // 1.上架 2.下架
  isLimit: '订阅商品是否限制商品', // 订阅商品是否限制商品(1.是 2.不是)
  limitTimeType: '限制的时间类型', // 限制的时间类型(1.day 2.month 3.year)
  limitTimeNumber: '限制的时间数量', // 限制的时间数量(限制多少天|月|年)
  limitTotalAmount: '一共限制多少秒', // 多少天|月|年一共限制多少秒
  limitPerAmount: '限制多少秒', // /1天|月|年限制多少秒
  createTime: '创建时间',
  updateTime: '修改时间',
  image: '图片地址'
}
export default {
  name: 'SyncPrice',
  filters: {
    timeType (type) {
      const map = { 1: 'day', 2: 'month', 3: 'year' }
      return map[type] || type
    },
    channelFilter (channel) {
      const map = { 1: '谷歌商城', 2: 'IOS商城', 3: 'Paypal', 4: 'Stripe' }
      return map[channel] || channel
    },
    typeFilter (type) {
      const map = { 1: '时长卡商品', 2: '会员商品' }
      return map[type] || type
    },
    experimentalGroupFilter (experimentalGroup) {
      const map = { 0: '基准组', 1: 'V1体验组', 2: 'V2体验组' }
      return map[experimentalGroup] || experimentalGroup
    },
    isDelFilter (isDel) {
      const map = { 1: '是', 2: '不是' }
      return map[isDel] || isDel
    },
    statusFilter (status) {
      const map = { 1: '上架', 2: '下架' }
      return map[status] || status
    },
    isLimitFilter (isLimit) {
      const map = { 1: '是', 2: '不是' }
      return map[isLimit] || isLimit
    },
    limitTimeTypeFilter (limitTimeType) {
      const map = { 1: 'day', 2: 'month', 3: 'year' }
      return map[limitTimeType] || limitTimeType
    }
  },
  data () {
    const numberCheck = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('此项必须为数字'))
      } else {
        callback()
      }
    }
    return {
      fieldMap,
      loading: false,
      tableLoading: false,
      dataList: [],
      formData: {
        goodsName: '',
        goodsId: '',
        packageName: '',
        timeType: '',
        timeTimes: '',
        channel: '',
        thirdGoodsId: '',
        pageNum: 1,
        pageSize: 20
      },
      channelList: [
        { code: 1, name: '谷歌商城' },
        { code: 2, name: 'IOS商城' },
        { code: 3, name: 'Paypal' },
        { code: 4, name: 'Stripe' }
      ],
      timeTypeList: [
        { code: 1, name: 'day' },
        { code: 2, name: 'month' },
        { code: 3, name: 'year' }
      ],
      rules: {
        timeTimes: [
          { validator: numberCheck }
        ]
      }
    }
  },
  computed: {
    ...mapState('rechargeSystem', ['priceListInfo'])
  },
  created () {
    this.queryPriceList(this.formData)
  },
  methods: {
    ...mapActions('rechargeSystem', ['queryPriceList']),
    handleSyncData () {
      this.loading = true
      updatePriceInfo()
        .then(res => {
          if (res.code === 200) {
            this.handleSearch()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryPriceList(this.formData)
    },
    handleCreateNewGoodsItem () {
      this.$router.push({
        name: 'GoodsItemCreate'
      })
    },
    handleUpdateStatus (row) {
      const TIPS = row.status === 1 ? '此操作将下架商品, 是否继续?' : '此操作将上架商品, 是否继续?'
      const nextStatus = row.status === 1 ? 2 : 1
      this.$set(row, 'loading', true)
      this.$confirm(TIPS, '提示', { type: 'warning ' })
        .then(() => {
          updateStatus({
            goodsId: row.goodsId,
            status: nextStatus
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功')
              this.queryPriceList(this.formData)
            }
          }).finally(() => {
            this.$set(row, 'loading', false)
          })
        })
        .catch((err) => {
          this.$set(row, 'loading', false)
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="less">
  .rowExpand{
    display: flex;
    flex-wrap: wrap;
    >li{
      width: 50%;
      display: flex;
      line-height: 1.5rem;
      .field{
        text-align: right;
        width: 130px;
        padding-right: 10px;
      }
      .value{
        flex: 1;
      }
    }
  }
</style>
